import Button from "@mui/material/Button";
import * as React from "react";
import { memo } from "react";
import FallingLettersAnim from "./falling_letters_anim"


     function scrollTo(item){
        var element = document.getElementById(item)
        if(item!=="Home"){
        element?.scrollIntoView({ 
          behavior: "smooth", 
          block: "start" });
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      
export default memo(function Navitems({navitems, lang}:{navitems:any, lang:string}){
       return( 
        navitems.map((item, i) => (
          <Button key={i}  onClick={() => scrollTo(item.en)}  color="inherit">
    
            <FallingLettersAnim
            text={lang==='hu' ? (item.hu).split(''):(item.en).split('')}
            start={true}
            anim={"random"}
          />
        
          </Button>
        ))
       )
      });
   
   