import {useState, useEffect} from 'react';

function keyToMove(key){
    const keys = {
        KeyW: 'forward',
        KeyS: 'backward',
        KeyA: 'left',
        KeyD: 'right',
        Space: 'jump',
    };
    return keys[key];
}

export const useMovement = () => {

    const [movement, setMovement] = useState({
        forward: false,
        backward: false,
        left: false,
        right: false,
        jump: false
      })
    
    useEffect(() => {
        const keyDownEvent = event => {
            setMovement(prevMove => ({...prevMove, [keyToMove(event.code)]:true}));
        };
        const keyUpEvent = event => {
            setMovement(prevMove => ({...prevMove, [keyToMove(event.code)]:false }));
        };
      
    document.addEventListener("keydown", keyDownEvent);
    document.addEventListener("keyup", keyUpEvent);

    return () => {
    document.removeEventListener("keydown", keyDownEvent);
    document.removeEventListener("keyup", keyUpEvent);
    };

    }, []);

    
      return movement;
}