import { useState,  useMemo} from 'react';
import {ColorModeContext} from "../../components/navbar";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { grey, deepOrange } from '@mui/material/colors'

export default function ToggleColorMode({children}) {

  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: {
              main: grey[600],
            },
            divider: grey[400],
            background: {
              default: grey[50],
              paper: grey[200],
              navbar: grey[400],
            },
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
            
          }
        : {
            // palette values for dark mode
            primary: {
              main: deepOrange[500],
            },
            divider: deepOrange[700],
            background: {
              default: grey[900],
              paper: grey[800],
            },
            text: {
              primary: grey[200],
              secondary: grey[300],
            },
          }),
    },
    typography:{
      fontFamily: `"Lexend", "Helvetica", sans-serif`,
      fontSize: 14,
      fontWeightRegular: 200,
      fontWeightMedium: 300,
      fontWeightBold: 400,
    },
    shape:{
      borderRadius: 8
    },
  });
  
  let theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  return (

      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>

  );
}