import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import cisco from "../../svg/cisco.svg";
import cplusplus from "../../svg/cplusplus.svg";
import docker from "../../svg/docker.svg";
import gitlab from "../../svg/gitlab.svg";
import python from "../../svg/python.svg";
import react from "../../svg/react.svg";

import Card from "../../components/card"


const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.h6,
  textAlign: "center",
  fontSize: "20",
  fontWeight: "600",
}));

const Content = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  fontWeight: "300",
  lineHeight: "1.5rem",
  textAlign: "center",
}));


const skills = [
  {
    logo: python,
    title: "Backend Development",
    text: <>
    <span>Python (Flask)</span><br/>
    <span>Node.js (Express)</span><br/>
    <span>ORM, REST..etc</span></>,
  },
  {
    logo: react,
    title: "Frontend Development",
    text: <>
    
    <span>React</span><br/>
    <span>TypeScript</span><br/>
    <span>Three.js</span></>,
  },
  {
    logo: cplusplus,
    title: "Industrial Development",
    text: <>
    <span>C++, C#, OT Networks</span><br/>
    <span>Omron, Mitsubishi PLC</span><br/>
    <span>Schneider PLC</span></>,
  },
  {
    logo: gitlab,
    title: "CI/CD",
    text: <>
    
    <span>Git (Gitlab)</span><br/>
    <span>Jira</span><br/>
    <span>Agile methodology</span></>,
  },
  {
    logo: cisco,
    title: "Network Configuration",
    text: <>
    <span>Cisco</span><br/>
    <span>Putty</span><br/>
    <span>WinSCP</span></>,
  },
  {
    logo: docker,
    title: "Distributed systems",
    text: <>
    <span>Docker, Linux</span><br/>
    <span>Cloud</span><br/>
    <span>Serverless arch.</span></>,
  },
];

export default function Skills() {

  return (
    <Box id="Skills" sx={{ my: "5rem", scrollMarginTop: "5rem" }}>
      <Box sx={{ display: "block" }}>
        <Grid
          container
          alignItems="stretch"
          spacing={{ xs: 2, md: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {skills.map((element, index) => (
            <Grid
              item
              style={{ display: "flex", flexDirection: "column" }}
              xs={4}
              sm={4}
              md={4}
              key={index}
            >
              <Card>
                <Box sx={{ height: 100, display: "block", marginTop: "15px", marginBottom: "15px"  }}>
                  <img
                    style={{ height: "100%", margin: "auto"}}
                    alt=""
                    src={element.logo}
                    loading="lazy"
                  />
                </Box>
                <Title >{element.title}</Title>
                <Content >{element.text}</Content>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}


/*
const skills = [
  {
    logo: python,
    title: "Backend Development",
    text: <>
    With a solid background in backend development, 
    I possess the expertise to build the foundation of robust web applications. 
    I am proficient in handling server-side logic, databases, and APIs, ensuring 
    seamless communication between the front end and the back end. My focus lies in
     creating secure, scalable, and high-performing systems that power 
    user interactions and deliver a dynamic user experience. I excel in technologies 
    such as 
    <p className="font-medium text-center leading-8">Python</span>
    <p className="font-medium text-center leading-8">C#</span>
    <p className="font-medium text-center leading-8">SQL technologies</span></>,
  },
  {
    logo: react,
    title: "Frontend Development",
    text: <>
    As a skilled frontend developer, I craft engaging and intuitive user interfaces
     that captivate users and enhance their interaction with web applications. 
    I prioritize responsive and cross-browser compatibility, 
     ensuring seamless functionality across various devices. I am committed to creating 
     visually appealing experiences that merge seamlessly with backend functionalities to 
     deliver a cohesive digital product.
    <p className="font-medium text-center leading-8">JavaScript</span>
    <p className="font-medium text-center leading-8">TypeScript</span>
    <p className="font-medium text-center leading-8">React</span></>,
  },
  {
    logo: cplusplus,
    title: "Industrial Development",
    text: <>All my life I was deeply interested in industrial automation development, I specialize in streamlining 
    processes and increasing efficiency. I am adept at designing and implementing automated 
    workflows, utilizing tools and scripts to eliminate repetitive tasks. My expertise in automation 
    development allows me to create efficient and responsive systems. I am experienced in programming 
    PCs, PLCs, microcontrollers, setting up complex systems.
    <p className="font-medium text-center leading-8">C++</span>
    <p className="font-medium text-center leading-8">Omron PLC</span>
    <p className="font-medium text-center leading-8">Allen-Bradley PLC</span></>,
  },
  {
    logo: gitlab,
    title: "CI/CD",
    text: <>
    I develop seamless CI/CD pipelines and automating build and deployment processes. 
    I am adept at designing and implementing automated workflows, tests, 
    utilizing tools and scripts to eliminate repetitive development tasks.
    These skills are enabling me to deliver efficient, fast and
    high-quality solutions in a collaborative and agile environment.
    <p className="font-medium text-center leading-8">Gitlab</span>
    <p className="font-medium text-center leading-8">Jira</span>
    <p className="font-medium text-center leading-8">Agile</span></>,
  },
  {
    logo: cisco,
    title: "Network Configuration",
    text: <>I possess a deep understanding of network protocols, routing, and security measures, 
    enabling me to design networks that ensure seamless data flow and safeguard against cyber threats. From
    setting up local area network in OT or IT environments, I am committed to optimizing network performance,
    connectivity, and data integrity.
    <p className="font-medium text-center leading-8">Cisco</span>
    <p className="font-medium text-center leading-8">Putty</span>
    <p className="font-medium text-center leading-8">WinSCP</span></>,
  },
  {
    logo: docker,
    title: "Distributed systems",
    text: <>I can create containers, manage images, and deploy containerized applications
     on Linux-based servers. This streamlines the development process but also enhances
      the scalability and reliability of our services. Furthermore, this offers enhanced control 
      and resource optimization, ensuring that our applications run smoothly
      and securely.
    <p className="font-medium text-center leading-8">Docker</span>
    <p className="font-medium text-center leading-8">Cloud</span>
    <p className="font-medium text-center leading-8">Serverless</span></>,
  },
];
*/