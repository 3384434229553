import * as React from "react";
import DemoSelector from "../../components/demo_selector/demo_selector"
import moonJpg from "./moon.jpg"
import earthJpg from "./earth.jpg"
import waterJpg from "./water.jpg"

export default function Index() {
    const images = [
        {position: [0, 0, 0.25], rotation: [0, 0, 0], url: moonJpg, displayname: "Demo 2 - Not yet available"  },
        {position: [-1.5, 0, 0.5], rotation: [0, Math.PI / 6, 0], url: earthJpg, displayname: "Demo 1 - Not yet available" },
        {position: [1.5, 0, 0.5], rotation: [0, -Math.PI / 6, 0], url: waterJpg, displayname: "Demo 3 - Not yet available" },
    ]
    return (
        <>
            <DemoSelector images={images}></DemoSelector>
            
        </>
    );
}