import React from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.h6,
  textAlign: "center",
  fontSize: "20",
  fontWeight: "600",
}));

const Content = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  fontWeight: "300",
  lineHeight: "1.5rem",
  textAlign: "center",
}));

const services = [{}];

export default function Services() {
  return (
    <Box
      id="Services"
      sx={{
        mt: "1rem",
        scrollMarginTop: "5rem",
      }}
    >
      <Grid
        container
        alignItems="stretch"
        spacing={{ xs: 2, md: 6 }}
        columns={{ xs: 6, md: 12 }}
      >
        {services.map((element, index) => (
          <Grid
            item
            style={{ display: "flex", flexDirection: "column" }}
            xs={6}
            sm={4}
            md={6}
            key={index}
          >
            <Box
              sx={{
                height: 100,
                display: "block",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <img
                style={{ height: "100%", margin: "auto" }}
                alt=""
                src={element.logo}
                loading="lazy"
              />
            </Box>
            <Title>{element.title}</Title>
            <Content>{element.text}</Content>
          </Grid>
        ))}
      </Grid>
      <Box className="w-full mx-auto text-center">
        <Typography variant="h4" component="h4" className="tracking-tight">
          Take a look at my demos
        </Typography>
        <Button
          sx={{ my: "2rem" }}
          size="large"
          variant="contained"
          href="demoselector"
        >
          DEMOS
        </Button>
      </Box>
    </Box>
  );
}
