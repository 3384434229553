import React, { useEffect,useState } from 'react';
import { Canvas} from '@react-three/fiber'
import { Physics, useBox} from '@react-three/cannon'
import { Sky } from '@react-three/drei'


import { Player } from './player';
import { Ground } from './ground';
import { useMouse } from './mouseclick'


const Box = ({ position }) => {
  const [ref] = useBox<any>(() => ({ mass: 1, position }));
  return (
    <mesh ref={ref} position={position}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
};



const FPSGame = () => {
    const [showInstructions, setShowInstructions] = useState(true)
    useMouse()
    function pointerlockchange() {
      setShowInstructions(!showInstructions)
    }
    useEffect(() => {
        document.addEventListener('pointerlockchange', pointerlockchange, false)
        return () => {
          document.removeEventListener('pointerlockchange', pointerlockchange, false)
        }
      })
      /*    <div id="instructions" className={showInstructions ? 'show' : 'hide'}>
          Instructions
          <button id="button">Click To Enter</button>
    </div> 
    selector="#button "
    */
  return (
    <>
    <Canvas shadows
      onCreated={({ gl }) => {

      }}>
        
      <Sky sunPosition={[50,20,50]}/>
      <ambientLight />
      <pointLight position={[50, 20, 50]} castShadow/>
      <Physics gravity={[0, -30, 0]}>
        <Box position={[5, 10, 5]} />
        <Player props/>
        <Ground />
      </Physics>
    </Canvas>

    </>
  );
};

export default FPSGame;
