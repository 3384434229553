import React, { useRef } from 'react';
import {useTrimesh } from '@react-three/cannon'
import {  Mesh } from 'three';




export const Ground = () => {

        
    
    const {
            attributes: { 
              position: { array: vertices },
            },
            index: { array: indices },
          } = geometry
        
    const [ref] = useTrimesh(
        () => ({
          args: [vertices, indices],
          mass: 0,

        }),
        useRef<Mesh>(null),
      )
    
    return (
      <mesh ref={ref} geometry={geometry} receiveShadow={true}>
        <planeGeometry args={[1000, 1000]} />
        <shadowMaterial opacity={0.1} />
  
      </mesh>
    );
  };

 