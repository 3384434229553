import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import ModalWithImageButton from "../../components/modal";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { LanguageContext } from ".";



const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  color: theme.palette.text.secondary,
  height: "100%",
  borderRadius: theme.shape.borderRadius,
}));


function Data(lang) {

  return([{
    image: {
      src: require("../../jpg/backend.jpg"),
      src_modal: require("../../jpg/backend.jpg"),
      title: "Backends",
      width: "50%",
    },
    text: {
      title: lang==='hu' ? "Automatizáció" :"Business automation",
      description: (
        lang==='hu' ?
        <> Python és Flask használatával konténeres backend-alkalmazásokra specializálódtam.
        Az egyik említésre méltó projektem egy korábban kézzel végzett ipari folyamat teljes automatizálása volt.
        <ul>
          <li>Python és Flask segítségével konténeres backend alkalmazásokat fejlesztettem.</li>
          <li>Automatizáltam egy korábban kézzel végzett ipari folyamatot.</li>
          <li>Adatbázis-tárolást integráltam a hatékony adatkezelés érdekében.</li>
          <li>Automatikus ellenőrzéseket vezettem be a folyamat pontosságának és megbízhatóságának biztosítása érdekében.</li>
          <li>Beállítottam a párhuzamos adatletöltést a teljesítmény optimalizálása érdekében.</li>
          <li>REST végpontok a más rendszerekkel való zökkenőmentes integrációhoz.</li>
          <li>Biztosítottam az alkalmazás architektúra skálázhatóságát és karbantarthatóságát.</li>
        </ul>
        </>
        :
        <>
          I specialize in containerized backend applications using Python and Flask.
          One of my notable projects involved the complete automation of an industrial process that was previously done manually.
          I built:
          <ul>
            <li>Developed containerized backend applications using Python and Flask.</li>
            <li>Automated an industrial process previously done manually.</li>
            <li>Integrated database storage for efficient data management.</li>
            <li>Implemented automatic checks to ensure process accuracy and reliability.</li>
            <li>Set up automatic parallel data downloads to optimize performance.</li>
            <li>Exposed REST endpoints for seamless integration with other systems.</li>
            <li>Ensured scalability and maintainability of the application architecture.</li>
          </ul>
        </>
        
        
      ),
    },
  },
  {
    image: {
      src: require("../../jpg/frontend.jpg"),
      src_modal: require("../../jpg/frontend.jpg"),
      title: "Frontend",
      width: "50%",
    },
    text: {
      title: lang==='hu' ? "Webes alkalmazások" : "Web apps",
      description: (
        lang==='hu' ? <>
        Dinamikus webalkalmazásokat készítek, amelyek kommunikálnak a backenddel.
           A preferált technológiám a React TypeScript-el, de megtanulhatok más frontend eszközöket is.
           Jártas vagyok:
           <ul>
             <li>A progresszív és dinamikus webes alkalmazásokban</li>
             <li>Reszponzív és felhasználóbarát webes felületekben a jobb felhasználói élmény érdekében.</li>
             <li>AI használata a gyors fejlesztés és a többféle prototípus elkészítése érdekében.</li>
             <li>Adatok gyűjtése és megjelenítése API-kból.</li>
             <li>Lekérdezés és socket kommunikáció megvalósítása a valós idejű adatokhoz.</li>
             <li>Böngészők közötti kompatibilitás és teljesítményoptimalizálás az optimális felhasználói élmény érdekében.</li>
             <li>Tapasztalatom van Three.js-hez és egy más korszerű könyvtárakhoz</li>
           </ul>
           </>:<>
          I create dynamic web applications that interact with backend systems. 
          My preferred technology is React with TypeScript, but I can also learn other frontend tools.
          I am skilled in:
          <ul>
            <li>Progressive and dynamic web applications</li>
            <li>Responsive and user-friendly web interfaces for enhanced user experience.</li>
            <li>I can leverage AI to shorten development times and to produce mass samples.</li>
            <li>Gathering and displaying data from backend APIs</li>
            <li>Implementing polling and socket communication for real-time updates.</li>
            <li>Cross-browser compatibility and performance optimization for optimal user experience.</li>
            <li>Experience with Three.js and another state of the art libraries</li>
          </ul>
        </>
      ),
    },
  },
  {
    image: {
      src: require("../../jpg/gitlab.jpg"),
      src_modal: require("../../jpg/gitlab.jpg"),
      title: "HMIs with AI",
      width: "50%",
    },
    text: {
      title: lang==='hu' ? "CI/CD Integráció" : "CI/CD Integration",
      description: (
        lang==='hu' ? <>
       Az automatizálásra összpontosítva CI/CD megoldásokat készítettem és teszteltem,
           fordítási, tesztelési és telepítési fázisok Linux szervereken.
           Tapasztalatom van:
           <ul>
             <li>A GitLab Runnert használó CI/CD-folyamatok Linux-környezetben.</li>
             <li>Docker konténerek a konzisztencia és a hordozhatóság érdekében.</li>
             <li>Tesztelési eljárások a kód minőségének és megbízhatóságának fenntartása érdekében.</li>
             <li>Build, test, deploy folyamat kialakítása.</li>
             <li>Tesztelt és optimalizált pipelineok a gyorsabb eredmények érdekében.</li>
             <li>Dokumentációt és képzést biztosítottam a csapatomnak.</li>
             <li>Frissítettem a meglévő szoftverfüggőségeket, és biztosítottam azok zökkenőmentes működését.</li>
           </ul>
           </>:
        <>
          With the focus on automation, I have built and tested CI/CD pipelines,
          building, testing and deployment phases mostly on Linux servers.
          I built:
          <ul>
            <li>CI/CD pipelines utilizing GitLab Runner on Linux environments.</li>
            <li>Docker containers for consistency and portability.</li>
            <li>Testing procedures to maintain code quality and reliability.</li>
            <li>Pipeline stages for building, unit testing, and deployment.</li>
            <li>Monitored and optimized pipeline performance for faster results.</li>
            <li>Provided documentation and training to team members.</li>
            <li>Upgraded existing software dependencies and ensured their seamless functionality.</li>
          </ul>
        </>
      ),
    },
  },{
    image: {
      src: require("../../jpg/cs.jpg"),
      src_modal: require("../../jpg/cs.jpg"),
      title: "HMIs with AI",
      width: "50%",
    },
    text: {
      title: lang==='hu' ? "PLC-PC Interfészek" : "Interface PLCs to PCs",
      description: (
        lang==='hu' ?
        <span>
        Speciális alkalmazásokat fejlesztettem, amelyek létfontosságú hídként 
        szolgáltak a programozható logikai vezérlők (PLC) és a személyi 
        számítógépek között, biztosítva a zökkenőmentes adatcserét. Ezek a 
        szoftverek megkönnyítették a PLC-k PC-rendszerekbe való zökkenőmentes
         integrálását, lehetővé téve az ipari folyamatok egyszerűsített 
         vezérlését és felügyeletét. Emellett kezeltem az SQL adatbázisokat,
          feltöltve azokat a strukturált hozzáféréshez és elemzéshez 
          szükséges adatokkal. Ezek az alkalmazások emberi gép interfészként
           (HMI) is szolgáltak, így felhasználóbarát felületeket terveztem, 
           növelve a működési hatékonyságot és minimalizálva a hibákat. A 
           prediktív döntéshozatal elősegítése érdekében a mesterséges
            intelligencia képességeit kihasználva olyan fejlett modelleket
             készítettem, amelyek régi és valós idejű adatok alapján
              előre jelezték az ipari folyamatok lehetséges változásait.
        </span>
        :
        <span>
          I developed specialized applications that served as a vital bridge
          between Programmable Logic Controllers (PLCs) and personal computers,
          ensuring seamless data exchange. These softwares facilitated the
          smooth integration of PLCs into PC systems, enabling streamlined
          control and monitoring of industrial processes. Additionally, I
          managed SQL databases, populating them with crucial data for
          structured access and analysis. These apps also served as a Human
          Machine Interface (HMI), so I designed user-friendly interfaces,
          enhancing operational efficiency and minimizing errors. To empower
          predictive decision-making, I leveraged the capabilities of artificial
          intelligence, constructing advanced models that forecasted potential
          changes in industrial processes based on historical and real-time
          data.
        </span>
      ),
    },
  },
  {
    image: {
      src: require("../../jpg/parallel.jpg"),
      src_modal: require("../../jpg/parallel.jpg"),
      title: "Controls with AI",
      width: "33%",
    },
    text: {
      title: lang==='hu' ? "Vezérlések AI-al" : "Controls with AI",
      description: (
        lang==='hu' ?
        <span>
        Backend fejlesztőként folyamatosan bizonyítottam,
         hogy képes vagyok robusztus és hatékony megoldások kidolgozására,
          amelyek a webalkalmazások szívét adják. A különféle
           programozási nyelvek és keretrendszerekben szerzett tapasztalatommal
            a kivételes felhasználói élményt nyújtó alkalmazások
             létrehozására specializálódtam. Munkámra
              jellemző a megbízhatóság, a precizitás,
               amelyek mindegyike kulcsfontosságú a zökkenőmentes működés
                biztosításához. Legyen szó adatbázis-műveletek optimalizálásáról,
                 API-k felépítéséről vagy biztonsági intézkedések bevezetéséről,
                  büszke vagyok a backend fejlesztéssel kapcsolatos aprólékos megközelítésemre.
        </span>:
        <span>
          As a backend developer, I have consistently demonstrated my ability to craft 
          robust and efficient solutions that power the heart of web applications. 
          With a strong foundation in a variety of programming languages and frameworks, 
          I specialize in creating the backbone of applications that deliver exceptional 
          user experiences. My work is characterized by its reliability, scalability, and 
          performance, all of which are crucial for ensuring seamless functionality. 
          Whether it's optimizing database operations, building APIs, or implementing security 
          measures, I take pride in my meticulous approach to backend development.
        </span>
      ),
    },
  },
  {
    image: {
      src: require("../../jpg/sauna.jpg"),
      src_modal: require("../../jpg/sauna_modal.jpg"),
      title: "Sauna/Pool control",
      width: "33%",
    },
    text: {
      title: lang==='hu' ? "Medence/Szauna vezérlések" : "Pool/Sauna controls",
      description: (
        lang==='hu' ?
        <span>
          Szabadidőmben szenvedélyesen foglalkozom vezérlőrendszerek fejlesztésével 
          különféle alkalmazásokhoz, különös tekintettel a medence- és szaunakörnyezetekre,
           ESP32 mikrokontrollereken használva C++ programozási nyelvet. Ez a hobbi nemcsak
            a mérnöki és technológiai elkötelezettségemet mutatja be, hanem bizonyítja,
             hogy képes vagyok alkalmazkodni és újítani az IoT és az intelligens otthoni
              megoldások gyorsan fejlődő világában.
        </span>
        :
        <span>
          In my spare time, I passionately pursue the craft of developing control systems 
          for various applications, with a particular focus on pool and sauna environments, 
          utilizing C++ programming language on ESP32 microcontrollers. This hobby not only 
          showcases my dedication to engineering and technology but also serves as a testament 
          to my ability to adapt and innovate in the rapidly evolving world of IoT and smart home solutions.
        </span>
      ),
    },
  }])
};


export default function Portfolio() {
  const {lang} = React.useContext(LanguageContext);
  return (
    <Box
      id="Portfolio"
      sx={{
        mt: "3rem",
        scrollMarginTop: "5rem",
      }}
    >
   
      <Typography sx={{ mt: "4vw", mb: "1vw" }} variant="h3">
        Portfolio
      </Typography>
      <Box
        sx={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%" }}
      >
        <Grid
          container
          alignItems="stretch"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Data(lang).map((element, index) => (
            <Grid
              item
              style={{ display: "flex", flexDirection: "column"}}
              xs={4}
              sm={4}
              md={4}
              key={index}
            >
              <Item elevation={12}>
                <ModalWithImageButton
                  picture={element.image.src}
                  picture_modal={element.image.src_modal}
                  title={element.text.title}
                  text={element.text.description}
                  index={index}
                />
              </Item>
            </Grid>
          ))}

        </Grid>
      </Box>
    </Box>
  );
}
