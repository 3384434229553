import React from 'react'
import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Text, Sparkles, Clouds, Cloud } from '@react-three/drei'

import { easing } from 'maath'

export default function DemoSelector({ images }) {
    return(
  <Canvas camera={{ fov: 70, position: [0, 2, 15] }} style={{position: "absolute", top: 0, left: 0}}>
    <color attach="background" args={['#000000']} />
    <fog attach="fog" args={['#000000', 10, 100]} />
    <group position={[0, -1, 0]}>
      <Frames images={images} />
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial
          blur={[900, 300]}
          resolution={1024}
          mirror={1}
          mixBlur={1}
          mixStrength={40}
          roughness={0.6}
          depthScale={2}
          minDepthThreshold={0.8}
          maxDepthThreshold={1.4}
          color="#2c2c2c"
          metalness={0.4}
        />
      </mesh>
    </group>
    <ambientLight color='#ffffff' intensity={1} />
    <pointLight position={[2,5,3]} intensity={20} />
    <pointLight position={[-2,5,3]} intensity={20} />
    <Clouds material={THREE.MeshBasicMaterial}>
        <Cloud position={[0,3,-5]} bounds={[7, 2, 1]} speed={0.1} segments={20}  seed={1} scale={1} volume={2} color="#008080"  />
        <Cloud position={[0,3,-5]} bounds={[7, 2, 1]} speed={0.2} segments={20}  seed={4} scale={1} volume={2} color="#008080"  />
        <Cloud position={[0,3,-5]} bounds={[7, 2, 1]} speed={0.3} segments={20}  seed={2} scale={1} volume={2} color="#008080"  />
        <Cloud position={[0,3,-5]} bounds={[7, 2, 1]} speed={0.1} segments={20}  seed={7} scale={1} volume={2} color="#008080"  />
    </Clouds>
    <Sparkles count={30} scale={4} size={8} speed={0.3} color="#d1d1d1" position={[2, 1, 0]} />
    <Sparkles count={30} scale={4} size={6} speed={0.4} color="#d1d1d1" position={[2, 1, 0]} />
    <Sparkles count={30} scale={4} size={8} speed={0.3} color="#d1d1d1" position={[-2, 1, 0]} />
    <Sparkles count={30} scale={4} size={6} speed={0.4} color="#d1d1d1" position={[-2, 1, 0]} />
  </Canvas>
    )
}



function callFunction(text){
    alert("Sorry, not yet available ")
}

function Frames({ images, quaternion = new THREE.Quaternion(), position = new THREE.Vector3() }) {
  const ref = useRef<any>()
  const clicked = useRef<any>()
  const[location, setLocation] = useState('/')
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(location)
    if (location.endsWith("_button")){
        callFunction(location)
    }
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(position.set(0, 1, 2))
      clicked.current.parent.getWorldQuaternion(quaternion)
    } else {
      position.set(0, 0, 5.5)
      quaternion.identity()
    }
  })
   

    useFrame((state, delta) => {
    easing.damp3(state.camera.position, position, 0.4, delta)
    easing.dampQ(state.camera.quaternion, quaternion, 0.4, delta)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        setLocation(clicked.current === e.object ? '' : e.object.name);
        }}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> )}
    </group>
  )
}

function Frame({ url, ...props }) {
  const imageMap = useLoader(THREE.TextureLoader, url) as THREE.Texture
  const frame = useRef<any>()
  const [hovered, hover] = useState(false)
  const[textShowed, showText] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = props.displayname

  useCursor(hovered)
  useFrame((state, delta) => {
    easing.dampC(frame.current.material.color, hovered ? "orange" : "white", 0.5, delta)
    imageMap.repeat.set(0.8 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 8, 0.8 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 8)
  })
  return (
    <group {...props}>
      <mesh
        name={url}
        onPointerOver={(e) => {
          e.stopPropagation();
          hover(true);
        }}
        onPointerOut={() => hover(false)}
        onClick={()=>showText(true)}
        onPointerMissed={()=>showText(false)}
        scale={[0.5,0.5, 0.01]}
        position={[0, 1, 0]}
        >
        <circleGeometry args={[1,128]}/>
        <meshStandardMaterial map={imageMap} />

        <mesh ref={frame} raycast={() => null} scale={[1.04, 1.04, 1.04]} position={[0, 0, -0.2]}>
          <circleGeometry args={[1,128]}/>
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
      </mesh>
      <Text whiteSpace='nowrap' maxWidth={0.1} anchorX="center" anchorY="top-baseline" position={[0, 1.63, 0]} fontSize={0.05}>
        {name}
      </Text>
      {textShowed
      ? <><Text name={url+"_button"} color="#ffffff" whiteSpace='nowrap' maxWidth={0.1} anchorX="center" anchorY="middle" position={[0, 1, 0.8]} fontSize={0.065}>
        Click me to continue
      </Text>
      <Text color="#ffffff" whiteSpace='nowrap' maxWidth={0.1} anchorX="center" anchorY="middle" position={[0, 0.90, 0.8]} fontSize={0.04}>
      Click outside to go back
    </Text></>
      :null}
    </group>
  )
}
