import {useEffect} from 'react';

function keyClicked(key){
    const keys = {
        0: 'leftClick',
        1: 'scrollClick',
        2: 'rightClick',
    };
    return keys[key];
}

export const useMouse = () => {

    const leftClickEvent = new CustomEvent('onLeftClick');
    const scrollClickEvent = new CustomEvent('onScrollClick');
    const rightClickEvent = new CustomEvent('onRightClick')

    useEffect(() => {
        const clickEvent = event => {
            switch (keyClicked(event.button)){
                case 'leftClick': 
                    document.dispatchEvent(leftClickEvent);
                    break;
                case 'scrollClick': 
                    document.dispatchEvent(scrollClickEvent);
                    break;
                case 'rightClick': 
                    document.dispatchEvent(rightClickEvent);
                    break;
            }
        };

    document.addEventListener("mousedown", clickEvent);

    return () => {
        document.removeEventListener("mousedown", clickEvent);
    };

    }, );

    
}