import React, { useRef } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { GLTF} from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useGLTF, SoftShadows } from "@react-three/drei";

type GLTFResult = GLTF & {
  nodes: {
    Beni: THREE.Mesh;
    Glasses: THREE.Mesh;
  };
  materials: {};
};

function Model(props) {
  let { nodes } = useGLTF(
    require("./beni_sculpture6.glb")
  ) as GLTFResult;
  const beniRef = useRef<any>({});

  useFrame(({ clock }) => (beniRef.current.rotation.y += 0.005));

  const material = new THREE.MeshStandardMaterial();
  material.color = new THREE.Color(0xffffff);
  material.roughness = 0.1;

  const glassmaterial = new THREE.MeshStandardMaterial();
  glassmaterial.color = new THREE.Color(0x000000);
  glassmaterial.roughness = 0.5;


  useThree(({ camera }) => {
    camera.rotation.set(THREE.MathUtils.degToRad(-12), 0, 0);
  });
  return (
    <group {...props} dispose={null} ref={beniRef}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Beni.geometry}
        material={material}
        position={[0, -18.115, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Glasses.geometry}
        material={glassmaterial}
        position={[-3.976, 12.341, -0.994]}
        rotation={[1.604, -0.099, 1.752]}
        scale={6}
      />
    </group>
  );
}

useGLTF.preload("/beni_sculpture6.glb");


function Ground() {
  return (
    <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry args={[100, 100]} />
      <shadowMaterial opacity={0.1} />
    </mesh>
  );
}

export default function BeniHead() {

  return (
    <Canvas  gl={{
      powerPreference: "high-performance",
      antialias: true,
    }}
    camera={{ position: [0, 20, 40], fov: 45}} shadows>
      <SoftShadows size={5} focus={0.4} samples={20}  />
      <Ground />
      <Model />
  


      <directionalLight
        position={[5, 5, 5]}
        castShadow
        intensity={3}
        color={0xffffff}
        shadow-mapSize={1024}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-100, 100, 100, -100]}
        />
      </directionalLight>
      <directionalLight
        position={[-5, 5, 5]}
        castShadow
        intensity={1}
        color={0xffffff}
        shadow-mapSize={1024}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-100, 100, 100, -100]}
        />
      </directionalLight>
    </Canvas>
  );
}
