import * as React from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";


import Home from "./pages/home/index";
import Error from "./pages/error/index";
import Demo from "./pages/demo/index";
import FPSDemo from "./pages/fpsdemo/index"; 
import NoPage from "./pages/nopage/index";
import Test from "./pages/test/index";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path ="/hu" element={<Navigate to="/" state='hu'/>}/>
        <Route path ="/en" element={<Navigate to="/" state='en'/>}/>
        <Route path="error" element={<Error />} />
        <Route path="demoselector" element={<Demo />} />
        <Route path="fpsdemo" element={<FPSDemo />} />
        <Route path="test" element={<Test />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
