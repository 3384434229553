import * as React from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { styled, useTheme } from "@mui/material/styles";

const ModalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "80vw",
  maxWidth: "700px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 150,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 300,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
  borderRadius: theme.shape.borderRadius,
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function ModalWithImageButton({
  picture,
  picture_modal,
  title,
  text,
  index,
}: {
  picture: string;
  picture_modal: string;
  title: string;
  text: string;
  index: number;
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  return (
    <div>
      <ImageButton
        focusRipple
        key={index}
        onClick={() => {
          handleOpen();
        }}
        style={{
          width: "100%",
        }}
      >
        <ImageSrc
          style={{
            backgroundImage: `url(${picture})`,
            borderRadius: theme.shape.borderRadius,
          }}
        />
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            sx={{
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {title}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Grow
          in={open}
          style={{
            transform: "translate(-50%, -50%)",
            transformOrigin: "left top",
          }}
        >
          <Box
            sx={ModalContainer}
            style={{ borderRadius: theme.shape.borderRadius }}
          >
            <Box className="w-full">
              <img
                src={picture_modal}
                alt={title}
                loading="lazy"
                style={{
                  borderRadius: theme.shape.borderRadius,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Box>
            <Box className="mt-4">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {title}
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {text}
              </Typography>
            </Box>
          </Box>
        </Grow>
      </Modal>
    </div>
  );
}
