import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { Divider } from "@mui/material";

import FallingLettersAnim from "../../components/falling_letters_anim"
import "./home.css"

import { LanguageContext } from ".";
import FallingTextAnim from "../../components/falling_text_anim"
import BeniHead from "../../components/beni_head/beni_head"

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between("xs,md")]: {
    marginTop: '0rem',
    marginBottom: '0rem',
  },
  [theme.breakpoints.up("md")]: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
}));

function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="100%"
      version="1.1"
      viewBox="-20.5 0 287 287"
    >
      <path
        fill="#d81b60"
        d="M153.86 263.302c1.359-.59 2.095-.605 2.53-.934 13.563-28.034 67.639-53.196 77.563-97.621 2.534-11.212 2.112-22.848-.844-34.271-6.124-24.54-26.605-41.464-51.732-42.945-13.091-.846-25.76.635-37.162 7.828-.845 6.134-1.478 12.269-2.745 17.981-2.111 9.308-6.968 16.924-15.625 21.578-6.335 3.597-12.669 3.597-16.047.212-4.435-4.443-4.434-10.366-1.69-15.232 3.801-6.769 8.446-13.116 13.303-19.039 3.167-3.808 7.39-6.981 11.191-10.155-3.59-23.905-26.183-34.059-53.21-24.116-.844 3.385-1.69 6.981-2.745 10.366-4.223 12.481-12.035 22-24.704 26.443-4.857 1.693-10.135 2.327-14.358-2.115-3.168-3.385-3.168-9.308.21-15.232 5.49-9.308 13.725-15.866 22.594-21.578 3.167-1.904 6.334-3.808 9.501-5.5-9.752-22.292 6.199-25.266 9.378-26.273 7.806-2.912 23.49-9.946 26.943-12.454 4.197-2.41 10.762-4.14 9.628 2.18-3.35 4.438-7.443 5.932-19.064 11.114-8.834 4.323-23.79 3.086-20.339 14.856.634 1.903 1.267 3.807 2.112 5.711 0 .212.422.423 1.266 1.058 1.9-.211 4.223-.211 6.546-.635 28.294-4.442 43.074 3.174 56.166 29.617 2.534-.846 5.49-1.692 8.235-2.75 16.258-5.5 32.517-5.923 48.986-.423 26.816 8.885 44.13 31.944 46.031 61.138 1.055 15.866-1.689 31.098-7.39 45.906-13.936 35.752-46.209 50.105-70.41 75.484-1.097 1.368-2.547 2.653-3.69 4.062-.1.24-.06.645-.089 1.934 8.21-.857 15.523-3.81 23.253-6.086 7.58-2.024 15.57-3.976 23.598-4.393.633 5.51-2.675 7.955-6.418 9.651-4.42 1.748-8.891 4.335-13.51 5.514-12.484 3.018-25.353 7.699-38.227 10.562-10.06 2.091-11.415-2.946-9.522-10.788 4.166-16.62-9.547-28.651-6.095-44.226.593-2.527 1.92-4.651 4.243-7.613 4.012 4.654 3.402 7.67 3.066 10.662-1.838 10.833 5.928 19.535 9.274 30.522zM43.92 94.935c14.57-1.904 23.649-11.212 24.282-24.328-9.713 6.347-19.003 12.059-24.282 24.329zm71.369 32.791c12.457-2.962 18.37-11.424 17.103-24.329-8.024 6.77-14.992 13.117-17.103 24.329z"
      ></path>
    </svg>
  );
}

export default function Home() {

  const {lang} = React.useContext(LanguageContext);



  return (
    <Box
      id="Home"
      sx={{
        mb: 40,
        scrollMarginBottom: "20rem",
      }}
    >    


      <Wrapper>

      <Typography  align="center" sx={{ my: "1vw" }} variant="h4">
      <FallingTextAnim  
        start={true}
        anim={"right"}
        >
        {lang==='hu'? "Igen, ez én vagyok" : "Yes, this is me"}
        </FallingTextAnim>
      </Typography>

      <Box class="m-auto aspect-square w-14">
      <Arrow/>
      </Box>

      <Box className="m-auto max-w-md aspect-square">
        <BeniHead/>
        </Box>



 
      </Wrapper>


      <Wrapper>
      <div
        className="max-w-lg w-full mx-auto text-center"
      >
        <Typography variant="h4" component="h1" className="tracking-tight">
        <FallingTextAnim
        start={true}
        anim={"left"}
        speed={1}
        >
          {lang==='hu'? <>Üdv, <span className="font-light">Kiss</span> Benedek vagyok</> : <>Hi, I am Benedek <span className="font-light">Kiss</span></>}
        </FallingTextAnim>
        </Typography>
        <Divider />
        <br />
        
        <Typography variant="h6" className="tracking-wider">
        <FallingLettersAnim
            text={lang==='hu' ? "Mérnökinformatikus" : "Software Engineer"}
            start={true}
            anim={"upright"}
          />
        </Typography>
        <br />
        <Typography variant="h6">
        <FallingLettersAnim
          text={lang==='hu' ? "PLC programozó, kávé rajongó" : "Coffee lover"}
          start={true}
          anim={"upright"}
        />
      </Typography>
    
      </div>
      </Wrapper>


    </Box>
    
  );
}
/*
<Grid container alignItems="center"  justifyContent="space-evenly" columnSpacing={{ xs: 1, md: 2 }}>

            <Grid item xs={12} md={4}>
              <Typography variant="h2" component="h1">
                I am<br/>Benedek<br/><span class="font-extrabold">Kiss</span>
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h6">Software and Mechatronics Engineer by profession</Typography>
              <br/>
              <Typography variant="h6">Amid hiker, nature lover, microcontroller geek</Typography>
            </Grid>

            <Grid item xs={12} md={8} class="max-w-xl">
              <Grow in="True">
                <img src={picture} alt="picture"  />
              </Grow>
            </Grid>

          </Grid>
*/
