import React, { useEffect, useRef } from 'react';
import {  useFrame, useThree } from '@react-three/fiber'
import { useSphere } from '@react-three/cannon'
import { PointerLockControls } from '@react-three/drei'
import {Vector3 } from 'three';

import { useMovement } from './movement';

export const Player = props => {
    const movement = useMovement();

    const velocity = useRef([0, 0, 0]);
    const {camera}  = useThree();

  
    const [playerBox, playerBoxApi] = useSphere<any>(() => 
    ({ mass: 1,
        type:'Dynamic', 
        position: [0, 20, 0],
        ...props
 }));
  
  
    useEffect(() => {
    playerBoxApi.velocity.subscribe(v =>(velocity.current = v));
    }, [playerBoxApi.velocity]);
   
    useFrame(() => {

    if (playerBox.current) camera.position.copy(playerBox.current.position)

    const direction = new Vector3();
      const frontVector = new Vector3(
        0,
        0,
        Number(movement.backward) - Number(movement.forward)
      );

      const sideVector = new Vector3(
          Number(movement.left) - Number(movement.right), 0, 0);
      direction
          .subVectors(frontVector, sideVector)
          .normalize()
          .multiplyScalar(5)
          .applyEuler(camera.rotation);
 
          playerBoxApi.velocity.set(direction.x,velocity.current[1],direction.z);
            playerBox.current.getWorldPosition(playerBox.current.position)
      if (movement.jump && playerBox.current?.position.y < 2) {
        playerBoxApi.velocity.set(velocity.current[0], 10, velocity.current[2]);
        }
    });
  
  
    return (
      <>
             <mesh ref={playerBox} />
             <PointerLockControls/>
      </>
    );
  };