import * as React from "react";
import { animated, useSpring } from '@react-spring/web'

export default function FallingTextAnim({children, start, anim}:{children: string, start: boolean, anim: string}) {

  const [delayedStart, setDelayedStart] = React.useState(false);
  const [actualText, setActualText] = React.useState<string>("");


  React.useEffect(() => {
  
    if (children !== actualText || start) {
      setDelayedStart(false);
      const actualDelay = 300;
        const timeout = setTimeout(() => {
          setActualText(children);
          setDelayedStart(true);
        }, actualDelay);

        return () => clearTimeout(timeout);

    }
// eslint-disable-next-line
  }, [start,children]);


  let from, to;

if(anim === "up"){
        from = {y: -1000, opacity: 0 }
        to = delayedStart ? {y: 0, opacity: 1 } : from
}
if(anim === "down"){
          from = {y: 1000,opacity: 0 }
          to = delayedStart ? {y: 0, opacity: 1 } : from
    }
if(anim === "right"){
      from = {x: 1000, y: 0, opacity: 0}
      to = delayedStart ? {x: 0, y:0, opacity: 1 } : from
}
if(anim === "left"){
  from = {x: -1000, y: 0, opacity: 0}
  to = delayedStart ? {x: 0, y:0, opacity: 1 } : from
}
if(anim === "random"){
  from = {y: Math.random()*1000-500, x:Math.random()*1000-500,opacity: 0 }
  to = delayedStart ? {y: 0, x:0, opacity: 1 } : from
}


    const springs = useSpring({
    from: from,
    to: to,
    config: { mass: 2, tension: 150, friction: 35}
    });

     return(
      <>
          <animated.p style={{...springs}} >
            {actualText}
          </animated.p>

     </>);

  };