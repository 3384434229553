import * as React from "react";
import { Box, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import "./nopage.css"

export default function Index() {
    return (
        <Box className="items-center justify-center flex h-full " >
            <SentimentVeryDissatisfiedIcon sx={{ fontSize: 300, color:"pink"}} />
            <Typography  variant="h3" component="h1" className="tracking-tight">
            <span className="font-extrabold text-9xl">404</span>
            <br/>
            Oh no!
            <br/> 
            Page not found
        </Typography>

        </Box>
    );
}