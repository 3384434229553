import React from "react";
import { Box } from "@mui/material";
import { IconButton, Link, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

export default function Contact() {
  return (
    <Box
      id="Contact"
      sx={{
        mt: "5rem",
        scrollMarginTop: "5rem",
      }}
    >
      <Box className="flex flex-col justify-center items-center m-0 p-0">
        <Typography variant="h5" gutterBottom>
          Contact Me
        </Typography>
        <Typography variant="body1" paragraph>
          Feel free to connect with me on social platforms or in email:
        </Typography>
      </Box>

      <Box className="flex flex-row justify-center items-center m-0 p-0">
        <Link
          href="https://www.linkedin.com/in/kissbeni"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <LinkedInIcon fontSize="large" />
          </IconButton>
        </Link>
        <Link
          href="mailto:business@kissbenedek.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <EmailIcon fontSize="large" />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
}
